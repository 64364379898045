.maptalks-toolbar-vertical {
  margin: 0;
  border: 1px solid #b8b8b8;
  overflow: visible;
}

.maptalks-toolbar-horizonal ul,
.maptalks-toolbar-vertical ul {
  margin: 0;
  padding: 0;
}

.maptalks-toolbar-vertical ul li+li {
  border-top: 1px solid #ddd;
}

.maptalks-toolbar-vertical li {
  text-align: center;
  list-style: none;
  line-height: 28px;
  font-size: 12px;
  color: #5a5a5a;
  background: #fff;
  min-width: 10px;
  min-height: 28px;
  position: relative;
  padding: 0 10px;
}

.maptalks-toolbar-vertical li:hover {
  color: #fff;
  background: #6a97d9;
}

.maptalks-toolbar-vertical li .maptalks-dropMenu {
  padding: 0;
  position: absolute;
  top: -1px;
  overflow: visible;
  border: 1px solid #b8b8b8;
}

.maptalks-toolbar-vertical li .maptalks-dropMenu li {
  list-style: none;
  min-width: 95px;
  background: #606976;
  height: 28px;
}

.maptalks-toolbar-vertical li .maptalks-dropMenu li+li {
  border-top: 1px solid #ddd;
}

.maptalks-toolbar-vertical li .maptalks-dropMenu li a {
  color: #fff;
  display: block;
  line-height: 27px;
  text-indent: 33px;
  text-decoration: none;
  font-size: 12px;
}

.maptalks-toolbar-vertical li .maptalks-dropMenu em.maptalks-ico {
  display: block;
  width: 5px;
  height: 6px;
  position: absolute;
  top: 12px;
  right: -4px;
}

.maptalks-toolbar-vertical li .maptalks-dropMenu li.maptalks-on,
.maptalks-toolbar-vertical li .maptalks-dropMenu li:hover {
  background: #4b545f;
}

.maptalks-toolbar-horizonal {
  margin: 0;
  overflow: visible;
}

.maptalks-toolbar-horizonal li {
  text-align: left;
  line-height: 28px;
  font-size: 12px;
  color: white;
  padding: 0 10px;
  list-style: none;
  min-width: 28px;
  min-height: 28px;
  float: left;
  background: rgba(0, 0, 0, 0.4);
  position: relative;
  border-radius: 5px;
  right: 500px;
}

.maptalks-toolbar-horizonal ul li:not(:last-child) {
  border-right-color: #ddd;
}

.maptalks-toolbar-horizonal ul li+li {
  border-left: none;
}

.maptalks-toolbar-horizonal li:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

.maptalks-toolbar-horizonal li .maptalks-dropMenu {
  display: block;
  position: absolute;
  left: -1px;
  overflow: visible;
}

.maptalks-toolbar-horizonal li .maptalks-dropMenu li {
  list-style: none;
  min-width: 95px;
  background: rgba(0, 0, 0, 0.4);
  height: 28px;
  border: 1px solid #b8b8b8;
  position: relative;
  right: 0px;
}

.maptalks-toolbar-horizonal li .maptalks-dropMenu ul li:first-child {
  border-top: none;
}

.maptalks-toolbar-horizonal li .maptalks-dropMenu ul li:not(:last-child) {
  border-bottom-color: #ddd;
}

.maptalks-toolbar-horizonal li .maptalks-dropMenu li+li {
  border-top: none;
}

.maptalks-toolbar-horizonal li .maptalks-dropMenu li a {
  color: #fff;
  display: block;
  line-height: 27px;
  text-indent: 20px;
  text-decoration: none;
  font-size: 12px;
}

.maptalks-toolbar-horizonal li .maptalks-dropMenu em.maptalks-ico {
  display: block;
  width: 5px;
  height: 6px;
  position: absolute;
  top: -4px;
  left: 12px;
}

.maptalks-toolbar-horizonal .maptalks-dropMenu li:hover {
  background: #4b545f;
}

.maptalks-toolbar-horizonal li .maptalks-dropMenu li a:before,.maptalks-toolbar-vertical li .maptalks-dropMenu li a:before{
  content:"";
  width:0;
  height:0;
  position:absolute;
  top:10px;
  left:14px;
  border-top:4px solid transparent;
  border-left:6px solid #fff;
  border-bottom:4px solid transparent
}