.ship-info-panel {
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  padding-top: 5px; /* Ajoutez un padding supplémentaire en haut pour le bouton */
  list-style: none;
  backdrop-filter: blur(3px); /* Ajoute un effet de flou */
  -webkit-backdrop-filter: blur(3px); /* safari */
}

.ship-info-h{

}

.ship-info-p{
  cursor: pointer;
  padding: 5px;
  margin: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
}
