.error-container {
  position: absolute;
  top: 10px; /* Place le message en haut */
  left: 50%; /* Centre horizontalement */
  transform: translateX(-50%); /* Ajuste pour être parfaitement centré */
  text-align: center; /* Centre le texte */
  width: 100%; /* Pour garantir le centrage */
}

.error-message {
  color: red;
  font-size: 16px;
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.1);
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
}
