/* SwitchToggleGrid.css */
.switch input
{
display: none;
}


/* Styles pour chaque switch */
.switch {
display: inline-block;
position: relative;
width: 30px; /* Largeur du switch */
height: 15px; /* Hauteur du switch */
vertical-align: middle; /* Aligner verticalement le switch par rapport au texte */
}

/* Styles pour le texte à côté des switchs */
.toggle-buttons-basemap-container div {
display: flex;
align-items: center; /* Aligner verticalement le texte et le switch */
gap: 10px; /* Espace entre les switchs */

}

/* Styles pour le slider de chaque switch */
.slider {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
border-radius: 30px;
box-shadow: 0 0 0 2px #777, 0 0 4px #777;
cursor: pointer;
border: 4px solid transparent;
overflow: hidden;
transition: 0.2s;
}

.slider:before {
position: absolute;
content: "";
width: 100%;
height: 100%;
background-color: #777;
border-radius: 30px;
transform: translateX(-15px); /*translateX(-(w-h))*/
transition: 0.2s;
}

input:checked + .slider:before {
transform: translateX(15px); /*translateX(w-h)*/
background-color: white;
}

input:checked + .slider {
box-shadow: 0 0 0 2px white, 0 0 8px white;
}