/* Le conteneur global */
.slider-map-container {
  position: fixed; /* ou 'relative' selon vos besoins */
  left: 50%; /* Centrer horizontalement */
  transform: translateX(-50%); /* Décaler de moitié pour centrer exactement */
  width: 100%; /* Ajustez selon la largeur souhaitée */
  z-index: 1000; /* Assurez-vous qu'il est au-dessus des autres éléments */
}

/* Le curseur lui-même */
.slider-map {
  -webkit-appearance: none; /* Supprimer le style par défaut pour Webkit/Blink */
  width: 100%; /* Utiliser toute la largeur du conteneur */
  height: 100vh; /* Hauteur du curseur */
  background: transparent; /* Fond du curseur */
  pointer-events: none;
  /*outline: none;  Supprimer le contour lors de la sélection */
  /*opacity: 1; /* Opacité du curseur */
   /*transition: opacity 0.2s; Transition pour l'effet de survol */
  position: absolute;

  
}





  /* Style de la poignée du curseur */
  .slider-map::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px; /* Largeur de la poignée */
    height: 100vh; /* Hauteur de la poignée égale à la hauteur de la fenêtre du navigateur */
    background: #fff; /* Fond de la poignée */
    /*cursor: pointer;*/
    border: 3px solid #333;
    border-radius: 0; /* Supprimer le border-radius pour avoir une poignée rectangulaire */
    pointer-events: auto; /* Activer les événements de pointage pour la poignée */
    position: relative;
}


  /* Style pour Firefox */
  .slider-map::-moz-range-thumb {
    width: 7px;
    height: 101vh;
    background: #fff;
    cursor: col-resize;
    border: transparent;
    pointer-events: auto;
    position: relative;
  }

/* Style pour Chrome, Safari et autres navigateurs basés sur Webkit */
.slider-map::-webkit-slider-thumb {
  -webkit-appearance: none; /* Supprimer l'apparence par défaut pour Webkit */
  appearance: none; /* Supprimer l'apparence par défaut pour une compatibilité plus large */
  width: 7px; /* Largeur de la poignée */
  height: 101vh; /* Hauteur de la poignée égale à la hauteur de la fenêtre */
  background: #fff; /* Fond de la poignée */
  cursor: col-resize; /* Curseur de redimensionnement */
  border: transparent; /* Pas de bordure */
  pointer-events: auto; /* Activer les événements de pointage pour la poignée */
  position: relative; /* Position relative pour des ajustements */
}




.slider-map-image {
    width: 20px; /* Définissez la taille de vos images */
    height: 20px;
    background-image: url("/public/images/slide.png");
   
}
