/*================ Login =====================*/


.col {
  font-size: 20px;
  position: absolute;
  right: 50%;
  z-index: 10000;

  background-color: rgba(255, 255, 255, 0.1); /* Fond semi-transparent */
  border: 10px; /* pase de bord */
  border-radius: 0px; /* Pas d'arrondis */
  padding: 0px; /* Un peu de padding pour l'esthétique */
  -webkit-appearance: none; /* Enlève l'apparence glossy sur Safari et Chrome */
  -moz-appearance: none; /* Enlève l'apparence glossy sur Firefox */
  appearance: none; /* Enlève l'apparence glossy par défaut */
}


.button-logout,.button-login {
  border: none;
  color: white;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;

  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 2px solid #555555;
}

.button-logout:hover,.button-login:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/*================ End Login =====================*/


/*================ Login-mail =====================*/

.login-mail {
  border: none;
  color: white;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;

  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 2px solid #555555;
}





/*================ End Login-mail =====================*/


.topnav {
  /*overflow: hidden;*/
  background-color: #e9e9e9;
  position: relative;
  z-index: 20000;
}

.topnav a {
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/*.topnav a:hover {
  background-color: #ddd;
  color: black;
}*/

.topnav a.active {
  background-color: #2196F3;
  color: white;
}

.topnav .login-container {
  float: right;
  background-color: rgba(0, 0, 0, 0.4);
}

.topnav input[type=text] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
  width:120px;
}

.topnav .login-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background-color: #555;
  color: white;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.topnav .login-container button:hover {
  background-color: red;
}

@media screen and (max-width: 600px) {
  .topnav .login-container {
    float: none;
  }
  .topnav a, .topnav input[type=text], .topnav .login-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .topnav input[type=text] {
    border: 1px solid #ccc;  
  }
}


/* Positionnement global */
.login-container {
  position: absolute;
  top: 10px; /* Distance depuis le haut */
  left: 180px; /* Distance depuis la droite */
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 8000; /* Assurez-vous que le composant soit au-dessus du contenu */
}

/* Avatar */
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #3282a7;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.avatar:hover {
  transform: scale(1.1);
}

/* Username */
.username {
  font-size: 16px;
  color: white;
  margin-right: 10px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.username:hover {
  color: #3282a7;
}

/* Dropdown menu */
.dropdown-menu {
  position: absolute;
  top: 50px; /* Distance sous l'avatar */
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 8000; /* Toujours au-dessus des autres éléments */
  padding: 10px;
  min-width: 150px;
}

/* Menu item */
.menu-item {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  color: white;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.menu-item:hover {
  background-color:  rgba(255, 255, 255, 0.2);
}

/* Divider line */
.divider {
  margin: 5px 0;
  border: 0;
  border-top: 1px solid #ccc;
}
