.App {
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0; /* Ajoutez cette ligne pour supprimer les marges */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





body, html {
  margin: 0 !important;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  margin-top: 0; /* Ajoutez cette ligne pour supprimer l'écart en haut */
  padding: 0;


}
#map {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0; /* Ajoutez cette ligne pour supprimer les marges */

}



#layer-selector {
  user-select: none;
  position: absolute;
  top: 4%;
  left: auto;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  padding-top: 0px; /* Ajoutez un padding supplémentaire en haut pour le bouton */
  list-style: none;
  backdrop-filter: blur(3px); /* Ajoute un effet de flou */
  -webkit-backdrop-filter: blur(3px); /* safari */
}
#draw-polygon-container {
  top: 180px;
}

.maptalks-overview {
  position: absolute !important; /* Override position if needed */
  bottom: -20px !important; /* Décalage depuis le bas */
  right: -20px !important; /* Décalage depuis la droite */
  background-color: rgb(0,0,0,0.5);
  opacity: 0.8;
  border-right-width: 0px;
  border-left-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  z-index: 1002;
}

/*.maptalks-overview-button {
  cursor:pointer;
  background:#fff;
  width:18px;
  height:18px;
  position:absolute;
  bottom: 1px;
  right: 1px;
  font: 16px sans-serif;
  text-align : center;
  line-height : 16px;
  border:1px solid #b4b3b3;
  color : #363539;
  z-index: 1002;
}*/


.layer-option {
  cursor: pointer;
  padding: 5px;
  margin: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none; /* Retire le contour blanc */
  font-size: 12px;
  handle: '.handle';
  text-align: middle;
}
.layer-option:hover {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1002;
  
}
.baselayer-option select {
  background-color: rgba(255, 255, 255, 0.1); /* Fond semi-transparent */
  color: white; /* Texte blanc */
  border: none; /* pase de bord */
  border-radius: 0; /* Pas d'arrondis */
  padding: 5px; /* Un peu de padding pour l'esthétique */
  -webkit-appearance: none; /* Enlève l'apparence glossy sur Safari et Chrome */
  -moz-appearance: none; /* Enlève l'apparence glossy sur Firefox */
  appearance: none; /* Enlève l'apparence glossy par défaut */
  width: 100%;	
}

.baselayer-option select::-ms-expand {
  display: none; /* Cache l'icône de flèche par défaut sur IE */
}

.baselayer-option select::after {
  position: absolute; /* Position absolue par rapport à son parent */
  top: 50%; /* Centre verticalement */
  right: 10px; /* Positionne à 10px du bord droit */
  transform: translateY(-50%); /* Ajuste pour centrer parfaitement */
  content: '▼'; /* Flèche vers le bas */
  color: white; /* Couleur blanche pour la flèche */
  /* Ajouter d'autres styles pour positionner et styliser la flèche si nécessaire */
}

.satellite-options-layer {
  color: black;
  
}

.sortable-ghost {
    opacity: 0.6; /* Style pour l'élément en déplacement */
}
.handle {
  cursor: move;
  display: inline-block; /* Pour que le handle soit à côté du texte */
  margin-right: 5px; /* Espace entre le handle et le séparateur */
  width: 20px; /* Largeur du handle */
  height: 20px; /* Hauteur du handle */
  /* TODO background-image: url('/images/icon/layers.png'); /* Remplacer par le chemin correct de votre image */
  background-position: center; /* Centrer l'image dans le handle */
  background-size: cover; /* Couvre toute la zone du handle */
  vertical-align: middle;
  border-right: 1px solid rgba(255, 255, 255, 0.2); /* Bordure semi-transparente */
  padding-left: 0px; /* Espace à gauche de l'icône */
  padding-right: 5px; /* Espace entre l'icône et le séparateur */


}


#date-and-buttons-container {
display: flex;
align-items: center; /* Alignez les éléments verticalement au centre */
justify-content: flex-start; /* Alignez les éléments horizontalement au début */
padding: 12px;
background: rgba(0, 0, 0, 0.4);
border-radius: 5px;
z-index: 1001;
position: absolute;
bottom: 1px;
left: 0;
right: 0;
backdrop-filter: blur(3px); /* Ajoute un effet de flou */
-webkit-backdrop-filter: blur(3px); /*safari*/

}


#date-selector-container {
display: flex;
flex-direction: column;
align-items: flex-start; /* Alignement à gauche des sélecteurs */
margin-right: 20px; /* Ajouter une marge à droite si nécessaire */
}

#day-selector .date-btn {
min-width: 26px; /* Largeur minimale, ajustez selon vos besoins */
margin: 0px;
}

#month-selector .date-btn {
padding: 5px 10px; /* Padding vertical et horizontal */
margin: 0px;
flex-grow: 1; /* Permet aux boutons de s'étendre pour remplir l'espace */
min-width: calc((31 * 26px) / 12);
}

#year-selector .date-btn {
padding: 5px 10px; /* Padding vertical et horizontal */
margin: 0px;
flex-grow: 1; /* Permet aux boutons de s'étendre pour remplir l'espace */
min-width: calc((31 * 26px) / 9);
}

#hour-selector .date-btn {
padding: 5px 10px; /* Padding vertical et horizontal */
margin: 0px;
flex-grow: 1; /* Permet aux boutons de s'étendre pour remplir l'espace */
min-width: calc((31 * 26px) / 24);
font-size: 8px; /* Taille de la police plus petite */

}

#date-counter-container {
  display: flex;
  padding: 2px; /* Espace autour des boutons */
  grid-gap: 2px; /* Espace entre les boutons */
  width: calc((31 * 26px));
  flex-wrap: nowrap;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center; /* Centre les éléments horizontalement */
  align-items: center;
  margin-right: 20px;

}
.small-custom-button {
  width: 60px; /* Largeur du bouton */
  height: 20px; /* Hauteur du bouton (pour rendre le bouton carré) */
  background-color: rgba(255, 255, 255, 0.1); /* Couleur de fond du bouton */
  color: white;
  border: none;
  display: flex; /* Utilisation de Flexbox pour centrer le contenu */
  align-items: center; /* Centrer verticalement */
  justify-content: center; /* Centrer horizontalement */
}

#date-counter .date-btn {
padding: 1px 1px; /* Padding vertical et horizontal */
margin: 0px;
min-width: 40px;
font-size: 8px; /* Taille de la police plus petite */
width: 40px; /* Largeur du handle */
height: 10px; /* Hauteur du handle */
background-color: rgba(255, 255, 255, 0.1);
flex-wrap: nowrap;

}

/*================ Date slider styles ===================== La modification des styles ne fonctionne pas !*/ 

.custom-slider {
font-size: 6px;
height: 2px;
width: 100%;
background-color: rgba(255, 255, 255, 0.7);
cursor: pointer;
display: flex;
flex-grow: 1;
-webkit-appearance: none;
}

.custom-slider::-webkit-slider-thumb {
-webkit-appearance: none;
width: 2em;
height: 2em;
background-color: rgba(200, 200, 200, 1);
border-radius: 1em;
border: 2px solid rgba(200, 200,200,1);
}

.custom-slider::-moz-range-thumb {
width: 2em;
height: 2em;
background-color: rgba(255, 255,255, 0.5);
border-radius: 1em;
border: 2px solid rgba(255,255,255, 1);
}

.custom-slider::-ms-thumb {
width: 2em;
height: 2em;
background-color: rgba(255, 0, 0, 0.5);
border-radius: 1em;
border: 2px solid rgba(255, 0, 0, 1);
}

.custom-slider:hover::-webkit-slider-thumb {
border-color: rgba(255, 255,255, 0.7);
}

.custom-slider:active::-webkit-slider-thumb {
border-color: rgba(255, 255, 255, 1);
}

.custom-slider::-webkit-slider-runnable-track,
.custom-slider::-moz-range-track,
.custom-slider::-ms-track,
.custom-slider::-mx-track {

height: 2px;
background: rgba(255, 0, 0, 0.5);
border-radius: 5px;
}

.custom-slider:focus {
outline: none;
}


/*================ Fin Date slider styles =====================*/




.date-selector {
display: flex;
justify-content: center;
align-items: center;
margin: 5px 0;
width: 100%;
flex-wrap: nowrap;
}

/* Style commun pour tous les boutons */
.date-btn {
cursor: pointer;
padding: 5px;
margin: 2px;
background-color: rgba(255, 255, 255, 0.1);
color: white;
border: none;
text-align: center;
flex-grow: 1;
flex-basis: calc((100% - 64px) / 31); /* 31 est le nombre maximum de boutons (jours) */
}


.date-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.selected {
  background-color: rgba(255, 255, 255, 0.4);
  color: black;
}

#date-selector-container {
  min-width: calc(31 * 28px + 60px);
}



#custom-buttons-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 4 colonnes */
  grid-gap: 10px; /* Espace entre les boutons */
  padding: 10px; /* Espace autour des boutons */
  margin-left: -60px;
}

.custom-button {
  width: 40px; /* Largeur du bouton */
  height: 40px; /* Hauteur du bouton (pour rendre le bouton carré) */
  background-color: rgba(255, 255, 255, 0.1); /* Couleur de fond du bouton */
  color: white;
  border: none;
  display: flex; /* Utilisation de Flexbox pour centrer le contenu */
  align-items: center; /* Centrer verticalement */
  justify-content: center; /* Centrer horizontalement */
}

.custom-button-off {
  width: 40px; /* Largeur du bouton */
  height: 40px; /* Hauteur du bouton (pour rendre le bouton carré) */
  background-color: transparent; /* Couleur de fond du bouton */
  color: white;
  border: none;
  display: flex; /* Utilisation de Flexbox pour centrer le contenu */
  align-items: center; /* Centrer verticalement */
  justify-content: center; /* Centrer horizontalement */
}


.svg-icon {
      stroke: white; /* Pour les traits */
      filter: brightness(0) invert(1);
      width: 50px; /* Ajustez selon vos besoins */
      height: 50px; /* Ajustez selon vos besoins */
}
#additional-layer-selector {
  position: absolute;
  top: 300px; /* Ajustez selon vos besoins */
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px; /* ajustez la marge selon vos besoins */
  z-index: 1000;

}

.additional-layer-option {
  cursor: pointer;
  padding: 5px;
  margin: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  handle: '.handle';
}

.additional-layer-option:hover, .additional-layer-option.selected {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Séparateur */
.separator {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid white;
}


/* Bouton pour afficher/masquer le sélecteur de couches */
.toggle-button {
  position: absolute;
  top: 6%;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1001;
  handle: '.handle';
}

/* Bouton pour afficher/masquer le sélecteur de date et l'overview */
.toggle-button-date {
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1002; /* Assurez-vous qu'il est au-dessus des autres éléments */
  position: absolute; /* Position relative pour les pseudo-éléments */
  padding: 0; /* Aucun padding pour que le contenu soit aligné avec les bords de la forme */
  overflow: visible; /* Permet aux pseudo-éléments de s'étendre hors du bouton */
  height: 20px; /* Hauteur réduite pour le bouton */
  width: 100px; /* Largeur fixe pour le bouton */
  line-height: 30px; /* Alignement vertical du symbole du triangle ajusté à la nouvelle hauteur */
  text-align: center; /* Alignement horizontal du symbole du triangle */
  bottom: 143px;
  left: 50%;
  transform: translateX(-50%);
}

@-moz-document url-prefix() {
  .toggle-button-date {
    bottom: 147px;
  }
}



  


/* Création du trapèze avec ::before */
.toggle-button-date::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Couleur de fond du trapèze */
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%); /* Forme en trapèze inversé */
  z-index: -1; /* Placer derrière le contenu du bouton */
}

/* Style pour le symbole du triangle */
.toggle-button-date::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent; /* Ajustez pour modifier la largeur */
  border-right: 6px solid transparent; /* Ajustez pour modifier la largeur */
  border-top: 10px solid white; /* Ajustez pour modifier la hauteur */
z-index: 1; /* Assurez-vous que le symbole est visible */
}
/* Style spécifique pour le bouton pointant vers le haut */
/* Création du trapèze avec ::before */
.toggle-button-date-up {
  background-color: transparent; /* Fond transparent */
  border: none;
  cursor: pointer;
  z-index: 1002; /* Assurez-vous qu'il est au-dessus des autres éléments */
  position: absolute; /* Position relative pour les pseudo-éléments */
  padding: 0; /* Aucun padding pour que le contenu soit aligné avec les bords de la forme */
  overflow: visible; /* Permet aux pseudo-éléments de s'étendre hors du bouton */
  height: 20px; /* Hauteur réduite pour le bouton */
  width: 100px; /* Largeur fixe pour le bouton */
  line-height: 30px; /* Alignement vertical du symbole du triangle ajusté à la nouvelle hauteur */
  text-align: center; /* Alignement horizontal du symbole du triangle */
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* Création du trapèze avec ::before */
.toggle-button-date-up::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Couleur de fond du trapèze */
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%); /* Forme en trapèze inversé */
  z-index: -1; /* Placer derrière le contenu du bouton */
}

/* Style pour le symbole du triangle */
.toggle-button-date-up::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent; /* Ajustez pour modifier la largeur */
  border-right: 6px solid transparent; /* Ajustez pour modifier la largeur */
  border-bottom: 10px solid white; /* Ajustez pour modifier la hauteur */
  z-index: 1; /* Assurez-vous que le symbole est visible */
}





/*========= Toggle button basemap ============*/

/* Styles pour le conteneur des switchs */
.toggle-buttons-basemap-container {
display: flex;
align-items: center; /* Aligner les éléments verticalement au centre */
grid-gap: 30px; /* Espace entre les boutons */
padding: 10px; /* Espace autour des boutons */
}




/*========= Fin Toggle button basemap ============*/



/* Classe pour masquer le sélecteur de couches */
.hidden {
  visibility: hidden;
}

#eodyn-logo-container {
position: absolute;
top: 1%;
left: 10px;
z-index: 1000; /* Assurez-vous qu'il est au-dessus de la carte */
}

#eodyn-logo-container img {
  height: 50px; /* ou la taille souhaitée */
  width: auto; /* pour garder les proportions */
}


/*  Base map slider    */

#layer-selector-slider {
  user-select: none;
  position: absolute;
  top: 10%;
  left: 0;
  right: auto;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  padding-top: 0px; /* Ajoutez un padding supplémentaire en haut pour le bouton */
  list-style: none;
  backdrop-filter: blur(3px); /* Ajoute un effet de flou */
  -webkit-backdrop-filter: blur(3px); /* safari */
}

/* Collapse */
#layer-selector-slider.hide {
  display: none;
}

#layer-selector.hide {
  display: none;
}

#additional-layer-selector-slider {
  position: absolute;
  top: 300px; /* Ajustez selon vos besoins */
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px; /* ajustez la marge selon vos besoins */
  z-index: 1000;

}

canvas, .layer {
  z-index: 40000; /* Adjust this based on your layer requirements */
}

.gen {
  user-select: none;  /* Empêche la sélection de texte */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
