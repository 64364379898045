
.maptalk-wrapper{
    position: absolute; 
    width: 100%;
    height: 100%;
}

.error-message-panel{
    top: 20%;
    left: 50%;
    padding: 10px;
    background-color: rgba(0,0,0,0.8);
    color: white;
    border-radius: 5px;
    font-size: 14px;
    z-index: 9999;
}

